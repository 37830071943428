<template>
  <AuctionHeader :activeSearchDesktop="false" :activeSearchMobile="false" />
  <section class="bg-agrogo-green-2">
    <div class="grid grid-cols-3 bg-agrogo-yellow-2 h-20 md:h-24">
      <div class="flex h-auto w-auto items-center justify-end">
        <span
          class="font-nexa font-black text-6xl md:text-7xl text-agrogo-yellow-2"
          >03</span
        >
      </div>
      <div
        class="col-span-2 flex flex-col items-start justify-center ml-5 md:ml-8"
      >
        <span class="font-nexa font-black text-base md:text-2xl mb-3 text-left"
          >Características
        </span>
        <div class="flex items-center space-x-4 md:space-x-7 md:text-base">
          <span class="icon-punto text-agrogo-green-3"></span>
          <span class="icon-punto text-white"></span>
        </div>
      </div>
    </div>
    <div
      class="grid grid-cols-1 lg:grid-cols-2 gap-3 mx-14 mt-8 mb-6 md:mx-0 padding-publish-farms"
    >
      <div class="grid grid-cols-1 gap-y-5 md:gap-y-3">
        <div
          class="grid grid-cols-2 gap-5 md:min-h-64 bg-container-steps-auction md:pl-5 md:pr-6"
        >
          <div
            class="flex items-center justify-start font-nexa font-normal text-sm text-white md:text-lg"
          >
            Raza
          </div>
          <div class="flex items-center justify-end">
            <v-select class="style-chooser2 w-full md:max-w-236">
              <template #no-options>Sin opciones</template>
            </v-select>
          </div>
        </div>
        <div class="grid grid-cols-1 gap-y-4 md:gap-y-0">
          <div
            class="grid grid-cols-2 gap-5 md:min-h-64 bg-container-steps-auction md:pl-5 md:pr-6"
          >
            <div
              class="flex items-center text-left font-nexa font-normal text-sm text-white md:text-lg"
            >
              Puro / Comercial
            </div>
            <div class="flex items-center justify-end">
              <v-select class="style-chooser2 w-full md:max-w-236 text-xs">
                <template #no-options>Sin opciones</template>
              </v-select>
            </div>
          </div>
          <div
            class="grid grid-cols-2 gap-5 md:min-h-64 bg-container-steps-auction md:pl-5 md:pr-6"
          >
            <div
              class="flex items-center text-left font-dinpro font-normal text-sm text-agrogo-gray-1 md:text-lg"
            >
              Cargar Registro
            </div>
            <div class="flex items-center justify-end">
              <button class="btn-load md:max-w-236 relative">
                Registro-Bovino.pdf
                <div
                  class="absolute inset-y-1 right-2 icon-upload text-agrogo-yellow-1"
                ></div>
              </button>
            </div>
          </div>
        </div>
        <div
          class="grid grid-cols-2 gap-5 md:min-h-64 bg-container-steps-auction md:pl-5 md:pr-6"
        >
          <div
            class="flex items-center justify-start font-nexa font-normal text-sm text-white md:text-lg"
          >
            Tipo
          </div>
          <div class="flex items-center justify-end">
            <v-select class="style-chooser2 w-full md:max-w-236 text-xs">
              <template #no-options>Sin opciones</template>
            </v-select>
          </div>
        </div>
        <div
          class="grid grid-cols-2 gap-5 md:min-h-64 bg-container-steps-auction md:pl-5 md:pr-6"
        >
          <div class="flex items-center text-left">
            <p class="font-nexa font-normal text-sm text-white md:text-lg">
              Edad Promedio
              <span class="font-dinpro text-xs md:text-sm"> (Meses)</span>
            </p>
          </div>
          <div class="flex items-center justify-end">
            <input class="input-standard-transparent w-full md:max-w-236" />
          </div>
        </div>
        <div
          class="grid grid-cols-2 gap-5 md:min-h-64 bg-container-steps-auction md:pl-5 md:pr-6"
        >
          <div class="flex items-center text-left">
            <p class="font-nexa font-normal text-sm text-white md:text-lg">
              Peso Promedio x Animal
              <span class="font-dinpro text-xs md:text-sm"> (Kg)</span>
            </p>
          </div>
          <div class="flex items-center justify-end">
            <input class="input-standard-transparent w-full md:max-w-236" />
          </div>
        </div>
        <div
          class="grid grid-cols-2 gap-5 md:min-h-64 bg-container-steps-auction md:pl-5 md:pr-6"
        >
          <div class="flex items-center text-left">
            <p class="font-nexa font-normal text-sm text-white md:text-lg">
              Precio Promedio x Kilo

              <span class="font-dinpro text-xs md:text-sm">
                (Pesos Colombianos)</span
              >
            </p>
          </div>
          <div class="flex items-center justify-end">
            <input class="input-standard-transparent w-full md:max-w-236" />
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 gap-y-5 md:gap-y-3">
        <div
          class="grid grid-cols-2 gap-5 md:min-h-64 bg-container-steps-auction md:pl-5 md:pr-6"
        >
          <div class="flex items-center text-left">
            <p class="font-nexa font-normal text-sm text-white md:text-lg">
              Precio Promedio x Animal

              <span class="font-dinpro text-xs md:text-sm">
                (Pesos Colombianos)</span
              >
            </p>
          </div>
          <div class="flex items-center justify-end">
            <input class="input-standard-transparent w-full md:max-w-236" />
          </div>
        </div>
        <div
          class="grid grid-cols-2 gap-5 md:min-h-64 bg-container-steps-auction md:pl-5 md:pr-6"
        >
          <div class="flex items-center text-left">
            <p class="font-nexa font-normal text-sm text-white md:text-lg">
              Número de Animales
            </p>
          </div>
          <div class="flex items-center justify-end">
            <input class="input-standard-transparent w-full md:max-w-236" />
          </div>
        </div>
        <div
          class="grid grid-cols-2 gap-5 md:min-h-64 bg-container-steps-auction md:pl-5 md:pr-6"
        >
          <div class="flex items-center text-left">
            <p class="font-nexa font-normal text-sm text-white md:text-lg">
              Peso Total
            </p>
          </div>
          <div class="flex items-center justify-end">
            <input class="input-standard-transparent w-full md:max-w-236" />
          </div>
        </div>
        <div
          class="grid grid-cols-2 gap-5 md:min-h-64 bg-container-steps-auction md:pl-5 md:pr-6"
        >
          <div class="flex items-center text-left">
            <p class="font-nexa font-normal text-sm text-white md:text-lg">
              Número de Hierros
            </p>
          </div>
          <div class="flex items-center justify-end">
            <input class="input-standard-transparent w-full md:max-w-236" />
          </div>
        </div>
        <div
          class="grid grid-cols-2 gap-5 md:min-h-64 bg-container-steps-auction md:pl-5 md:pr-6"
        >
          <div
            class="flex items-center text-left font-nexa font-normal text-sm text-white md:text-lg"
          >
            Enfermedad Defecto Físico
          </div>
          <div class="flex items-center justify-end">
            <v-select class="style-chooser2 w-full md:max-w-236 text-xs">
              <template #no-options>Sin opciones</template>
            </v-select>
          </div>
        </div>
      </div>
    </div>
    <div
      class="bg-agrogo-yellow-1 h-14 md:h-20 flex items-center justify-center space-x-3 md:space-x-4 lg:space-x-4"
    >
      <button @click="previus()" type="button" class="btn-primary">
        <span
          class="icon-desplegar flex items-center transform rotate-90 text-agrogo-yellow-2 text-xs mr-1 leading-none"
        ></span>
        Anterior
      </button>
      <button @click="next()" type="button" class="btn-primary">
        Siguiente
        <span
          class="icon-desplegar flex items-center transform -rotate-90 text-agrogo-yellow-2 text-xs ml-1 leading-none"
        ></span>
      </button>
    </div>
  </section>
</template>
<script>
import AuctionHeader from "@/components/header/HeaderAuction";
export default {
  components: {
    AuctionHeader,
  },
};
</script>
